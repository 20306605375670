// Core
import React, {useState, useCallback, useEffect} from 'react'
import {graphql} from 'gatsby'
import {useQueryParam, StringParam} from 'use-query-params'

// Services
import {getPlatform, getRapid} from '../../services/'

// Styles
import * as styles from '../../assets/css/pages/onesearch.module.css'

//Componentes
import OnesearchTemplate from '../../templates/onesearch'
import CtaDefault from '../../components/Onesearch/CtaDefault'
import HeroDefault from '../../components/Onesearch/HeroDefault'
import PrivacyDefault from '../../components/Onesearch/PrivacyDefault'
import ColumnsDefault from '../../components/Onesearch/ColumnsDefault'

export default function Edge({data: {content, logoImage}}) {
  const [gclidParam] = useQueryParam('gclid', StringParam)
  const [uuidParam] = useQueryParam('uuid', StringParam)
  const [windowContext, setWindowContext] = useState(null)

  const [pageContent] = useState(() => {
    return {
      ...content,
      sub_title: content.extensions.sub_title,
    }
  })

  const [ctaContent] = useCtaContent(content)

  const onClickRapidHandler = useCallback(() => {
    const platform = getPlatform()
    const rapidInstance = getRapid(content.config.rapid.init)

    const lls = {}
    lls.ll1 = 'onesearch'
    lls.ll2 = platform.browser.alias || platform.ua
    if (gclidParam) lls.ll3 = gclidParam
    if (uuidParam) lls.ll4 = uuidParam

    rapidInstance.beaconClick('page', ctaContent.btn.label, 1, {
      ...rapidInstance._normalizeTar(ctaContent.btn.url),
      itc: 0,
      cpos: 2,
      elm: 'btn',
      ...lls,
    })
  }, [ctaContent])

  const onClickNewWindowHandler = useCallback(
    (e) => {
      e.preventDefault()

      const extensionWindow = 'extensionWindow'
      const extensionWindowParams = [
        'menubar=yes',
        `left=${window.screenX}`,
        `top=${window.screenY}`,
        `width=${window.innerWidth}`,
        `height=${window.innerHeight}`,
      ].join(',')
      if (windowContext && !windowContext.closed) {
        window.open('', extensionWindow).focus()
      } else {
        const newWindowContext = window.open(ctaContent.btn.url, extensionWindow, extensionWindowParams)
        newWindowContext.focus()
        setWindowContext(newWindowContext)
      }
    },
    [windowContext, ctaContent]
  )

  const onClickHandler = (e) => {
    onClickRapidHandler()
    onClickNewWindowHandler(e)
  }

  return (
    <OnesearchTemplate content={content} logoImage={logoImage}>
      <HeroDefault content={pageContent}>
        <CtaDefault content={ctaContent} onClickHandler={onClickHandler} />
      </HeroDefault>
      <PrivacyDefault />
      <ColumnsDefault>
        <CtaDefault content={ctaContent} onClickHandler={onClickHandler} />
      </ColumnsDefault>
    </OnesearchTemplate>
  )
}

function useCtaContent(content) {
  const [ctaContent, setCtaContent] = useState(() => {
    return {
      btn: {
        ...content.extensions.btn,
        ...content.extensions.browsers.edge,
        url: content.extensions.browsers.edge.link,
      },
      desc: content.extensions.desc,
    }
  })

  useEffect(() => {
    const search = typeof location !== 'undefined' ? location.search.replace(/^\/?\?/, '&') : ''
    ctaContent.btn.url = ctaContent.btn.url + search
    setCtaContent({...ctaContent})
  }, [])

  return [ctaContent]
}

// query
export const pageQuery = graphql`
  query {
    content: onesearchpageJson {
      config {
        rapid {
          init {
            spaceid
            keys {
              pt
              ver
            }
          }
        }
      }
      extensions {
        sub_title
        desc
        btn {
          label
        }
        browsers {
          edge {
            link
          }
        }
      }
      default {
        btn {
          url
        }
      }
    }
    logoImage: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "yahoo_logo_purple.png"}) {
      sharp: childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
